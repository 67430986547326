import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import ArticlesComponent from "../components/blog/articles"
import Footer from "../components/footer"

const IndexPage = () => {
  const data = useStaticQuery(query)

  const services = data.allStrapiServices.edges
  const notFirstService = data.allStrapiServices.edges.filter((_, i) => i !== 0)

  const caseStudies = data.allStrapiCaseStudies.edges
  const notFirstCaseStudy = data.allStrapiCaseStudies.edges.filter(
    (_, i) => i !== 0
  )

  const testimonial = data.allStrapiTestimonials.edges
  const notFirstTestimonial = data.allStrapiTestimonials.edges.filter(
    (_, i) => i !== 0
  )
  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="my-12">
        <div className="lg:mx-14 px-4 sm:px-6">
          <div className="text-primary text-left">
            <h1 className="lg:display-1 text-small font-bold">
              Articulate Ventures is a PR &amp; Marketing firm that uses
              unconventional tactics to get you the right kind of attention.
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div className="max-w-2xl ml-auto px-4 lg:py-6 pb-16 sm:px-6">
          <div>
            <p className="mt-4 text-lg leading-6 text-primary">
              Most PR firms are focused on polished graphics and mainstream
              media that is simple to quantify and carries both little risk and
              even less reward. They offer feedback and strategies that are easy
              to say yes to. This will keep you playing the same games as
              everyone else. We find ways to engage where the competition
              is much less fierce so your time and energy results in your
              audience taking the action you need them to take.
            </p>
          </div>
        </div>
      </div>
      <div
        className="rounded-b-2xl lg:rounded-b-3xl lg:py-8"
        style={{ boxShadow: "0px 0px 15px 3px #0000001A" }}
      >
        <div className="lg:flex mx-6 lg:mx-16 gap-16 border-b lg:border-gray-300 border-black py-6">
          <div className="lg:w-1/3 lg:text-small text-2xl pb-4">
            <p className="text-primary">
              Here are some of our current offerings.{" "}
            </p>
            <Link to="/services">
              {" "}
              <p className="text-blue-500 py-4">See all our services.</p>
            </Link>
          </div>
          <div class="flex-1 grid grid-rows-2 grid-flow-row gap-8">
            <div class="lg:col-span-4">
              <Link
                to={`/services/${
                  services[0].node.externalLink
                    ? services[0].node.externalLink
                    : services[0].node.slug
                }`}
                className="relative flex  rounded-xl hover:card-shadow"
              >
                <img
                  src={services[0].node.image.url}
                  alt={`Hero image`}
                  className="absolute inset-0 w-full h-full object-center object-cover rounded-xl"
                />
                <div class="h-80 relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg">
                  <div class="flex gap-4">
                    {services[0].node.categories.map(category => (
                      <p
                        class="px-3 py-1 border rounded-full"
                        key={category.slug}
                      >
                        {category.title}
                      </p>
                    ))}
                  </div>
                  <div>
                    <p class="font-semibold">{services[0].node.title}</p>
                    <span class="">{services[0].node.description}</span>
                  </div>
                </div>
              </Link>
            </div>
            {notFirstService.map(service => (
              <div class="lg:col-span-2" key={service.strapiId}>
                {service.node.externalLink ? (
                  <a
                    href={`${service.node.externalLink}`}
                    target="_blank"
                    className="relative flex  rounded-xl hover:card-shadow"
                  >
                    <img
                      src={service.node.image.url}
                      alt={`Hero image`}
                      className="absolute inset-0 w-full h-full object-center object-cover rounded-xl"
                    />
                    <div class="h-80 relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg">
                      <div class="flex gap-4">
                        {service.node.categories.map(category => (
                          <p
                            class="px-3 py-1 border rounded-full"
                            key={category.slug}
                          >
                            {category.title}
                          </p>
                        ))}
                      </div>
                      <div>
                        <p class="font-semibold">{service.node.title}</p>
                        <span class="">{service.node.description}</span>
                      </div>
                    </div>
                  </a>
                ) : (
                  <Link
                    to={`/services/${
                      service.node.externalLink
                        ? service.node.externalLink
                        : service.node.slug
                    }`}
                    className="relative flex  rounded-xl hover:card-shadow"
                  >
                    <img
                      src={service.node.image.url}
                      alt={`Hero image`}
                      className="absolute inset-0 w-full h-full object-center object-cover rounded-xl"
                    />
                    <div class="h-80 relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg">
                      <div class="flex gap-4">
                        {service.node.categories.map(category => (
                          <p
                            class="px-3 py-1 border rounded-full"
                            key={category.slug}
                          >
                            {category.title}
                          </p>
                        ))}
                      </div>
                      <div>
                        <p class="font-semibold">{service.node.title}</p>
                        <span class="">{service.node.description}</span>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* <div className="lg:flex mx-6 lg:mx-16 gap-16 py-6 border-b lg:border-gray-300 border-black">
          <div className="lg:w-1/3 lg:text-small text-2xl pb-4">
            <p className="text-primary py-4">
              Here's what people are saying about their experience.{" "}
            </p>
          </div>
          <div class="flex-1 grid grid-rows-2 grid-flow-row gap-8">
            <div class="lg:col-span-4">
              <Link
                to="/"
                className="relative flex  rounded-xl hover:card-shadow"
              >
                <img
                  src={caseStudies[0].node.image.url}
                  alt={`Hero image`}
                  className="absolute inset-0 w-full h-full object-center object-cover rounded-xl"
                />
                <div class="h-80 relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg">
                  <div class="flex gap-4">
                    {caseStudies[0].node.categories?.map(category => (
                      <p
                        class="px-3 py-1 border rounded-full"
                        key={category.slug}
                      >
                        {category.title}
                      </p>
                    ))}
                  </div>
                  <div>
                    <p class="font-semibold">{caseStudies[0].node.title}</p>
                    <span class="">{caseStudies[0].node.description}</span>
                  </div>
                </div>
              </Link>
            </div>
            {notFirstCaseStudy.map(caseStudy => (
              <div class="lg:col-span-2" key={caseStudy.strapiId}>
                <Link
                  to="/"
                  className="relative flex  rounded-xl hover:card-shadow"
                >
                  <img
                    src={caseStudy.node.image.url}
                    alt={`Hero image`}
                    className="absolute inset-0 w-full h-full object-center object-cover rounded-xl"
                  />
                  <div class="h-80 relative w-full flex flex-col bg-black bg-opacity-40 justify-between p-4 text-white rounded-xl shadow-lg">
                    <div class="flex gap-4">
                      {caseStudy.node.categories.map(category => (
                        <p
                          class="px-3 py-1 border rounded-full"
                          key={category.slug}
                        >
                          {category.title}
                        </p>
                      ))}
                    </div>
                    <div>
                      <p class="font-semibold">{caseStudy.node.title}</p>
                      <span class="">{caseStudy.node.description}</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div> */}
        <div
          className="rounded-b-2xl lg:rounded-b-3xl"
          style={{ boxShadow: "0px 0px 15px 3px #0000001A" }}
        >
          <div className="lg:flex mx-6 lg:mx-16 gap-16 py-6">
            <div className="lg:w-1/3 lg:text-small text-2xl pb-4">
              <p className="text-primary py-4">Don't take our word for it. </p>
              <p className="text-blue-500 py-4">Trust our customers</p>
            </div>
            <div class="flex-1 grid grid-rows-2 grid-flow-row gap-8">
              <div
                class="lg:col-span-4 row-span-2 box-testimonial"
                key={testimonial[0].strapiId}
              >
                <div className="flex items-center mb-5">
                  <div className="testimonial-image">
                    <img
                      src={testimonial[0].node.picture.url}
                      alt={`Hero image`}
                    />
                    <div className="initial-circle">
                      {testimonial[0].node.name.charAt(0)}
                    </div>
                  </div>
                  <div>
                    <h4 className="font-extrabold	text-lg">
                      {testimonial[0].node.name}
                    </h4>
                    <p>{testimonial[0].node.title_of_person}</p>
                  </div>
                </div>
                <hr className="testimonial-hr" />
                <div class="  w-full flex flex-col  justify-between pt-4  ">
                  <div>
                    <span class="">{testimonial[0].node.testimonial}</span>
                  </div>
                </div>
              </div>

              {notFirstTestimonial.map(testimonial => (
                <div
                  class="lg:col-span-2 box-testimonial"
                  key={testimonial.strapiId}
                >
                  <div className="flex items-center mb-5">
                    <div className="testimonial-image">
                      <img
                        src={testimonial.node.picture.url}
                        alt={`Hero image`}
                      />
                      <div className="initial-circle">
                        {testimonial.node.name.charAt(0)}
                      </div>
                    </div>
                    <div>
                      <h4 className="font-extrabold	text-lg">
                        {testimonial.node.name}
                      </h4>
                      <p>{testimonial.node.title_of_person}</p>
                    </div>
                  </div>
                  <hr className="testimonial-hr" />
                  <div class="  w-full flex flex-col  justify-between pt-4  ">
                    <div>
                      <span class="">{testimonial.node.testimonial}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiArticles {
      edges {
        node {
          strapiId
          slug
          title
          image {
            url
          }
          author {
            name
          }
          categories {
            slug
            title
          }
        }
      }
    }
    allStrapiServices(filter: { featured: { eq: true } }) {
      edges {
        node {
          strapiId
          slug
          externalLink
          title
          image {
            url
          }
          categories {
            slug
            title
          }
          description
        }
      }
    }
    allStrapiCaseStudies(filter: { featured: { eq: true } }) {
      edges {
        node {
          strapiId
          slug
          title
          image {
            url
          }
          categories {
            slug
            title
          }
          description
        }
      }
    }
    allStrapiTestimonials(filter: { featured: { eq: true } }) {
      edges {
        node {
          strapiId
          title_of_person
          picture {
            url
          }
          testimonial
          name
        }
      }
    }
  }
`

export default IndexPage
